<script setup lang="ts">
import type { AppSpinnerProps } from './types';

const props = withDefaults(defineProps<AppSpinnerProps>(), {
  size: 24,
  type: 'primary',
});

const computedSizeStyle = computed(() => {
  return {
    width: `${props.size}px`,
    height: `${props.size}px`,
  };
});
</script>

<template>
  <div
    class="app-spinner"
    :class="type"
    :style="computedSizeStyle" />
</template>

<style lang="scss" src="./style.scss" />
